import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { usersSlice } from "../_redux/users/usersSlice";
import { ordersSlice } from "../_redux/orders/ordersSlice";
import { strategiesSlice } from "../_redux/strategies/strategiesSlice";
import { paymentsSlice } from "../_redux/payments/paymentsSlice";
import { settingsSlice } from "../_redux/settings/settingsSlice";
import { transactionsSlice } from "../_redux/transactions/transactionsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: usersSlice.reducer,
  orders: ordersSlice.reducer,
  strategies: strategiesSlice.reducer,
  payments: paymentsSlice.reducer,
  settings: settingsSlice.reducer,
  transactions: transactionsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
