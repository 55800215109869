import React from "react";
import { Route } from "react-router-dom";
import { StrategiesLoadingDialog } from "./strategies-loading-dialog/StrategiesLoadingDialog";
import { StrategyEditDialog } from "./strategy-edit-dialog/StrategyEditDialog";
import { StrategyDeleteDialog } from "./strategy-delete-dialog/StrategyDeleteDialog";
import { StrategiesDeleteDialog } from "./strategies-delete-dialog/StrategiesDeleteDialog";
import { StrategiesFetchDialog } from "./strategies-fetch-dialog/StrategiesFetchDialog";
import { StrategiesUpdateStateDialog } from "./strategies-update-status-dialog/StrategiesUpdateStateDialog";
import { StrategiesUIProvider } from "./StrategiesUIContext";
import { StrategiesCard } from "./StrategiesCard";

export function StrategiesPage({ history }) {
  const strategiesUIEvents = {
    newStrategyButtonClick: () => {
      history.push("/strategies/new");
    },
    openEditStrategyDialog: (id) => {
      history.push(`/strategies/${id}/edit`);
    },
    openDeleteStrategyDialog: (id) => {
      history.push(`/strategies/${id}/delete`);
    },
    openDeleteStrategiesDialog: () => {
      history.push(`/strategies/deleteStrategies`);
    },
    openFetchStrategiesDialog: () => {
      history.push(`/strategies/fetch`);
    },
    openUpdateStrategiesStatusDialog: () => {
      history.push("/strategies/updateStatus");
    },
    openViewStrategyDialog: (id) => {
      history.push(`/strategies/${id}/view`);
    },
  };

  return (
    <StrategiesUIProvider strategiesUIEvents={strategiesUIEvents}>
      <StrategiesLoadingDialog />
      <Route path="/strategies/new">
        {({ history, match }) => (
          <StrategyEditDialog
            show={match != null}
            onHide={() => {
              history.push("/strategies");
            }}
          />
        )}
      </Route>
      <Route path="/strategies/:id/edit">
        {({ history, match }) => (
          <StrategyEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/strategies");
            }}
            view={false}
          />
        )}
      </Route>
      <Route path="/strategies/:id/view">
        {({ history, match }) => (
          <StrategyEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/strategies");
            }}
            view={true}
          />
        )}
      </Route>
      <Route path="/strategies/deleteStrategies">
        {({ history, match }) => (
          <StrategiesDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/strategies");
            }}
          />
        )}
      </Route>
      <Route path="/strategies/:id/delete">
        {({ history, match }) => (
          <StrategyDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/strategies");
            }}
          />
        )}
      </Route>
      <Route path="/strategies/fetch">
        {({ history, match }) => (
          <StrategiesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/strategies");
            }}
          />
        )}
      </Route>
      <Route path="/strategies/updateStatus">
        {({ history, match }) => (
          <StrategiesUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/strategies");
            }}
          />
        )}
      </Route>
      <StrategiesCard />
    </StrategiesUIProvider>
  );
}
