import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  // CardHeader,
  // CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { OrdersFilter } from "./orders-filter/OrdersFilter";
import { OrdersTable } from "./orders-table/OrdersTable";
import { OrdersGrouping } from "./orders-grouping/OrdersGrouping";
import { useOrdersUIContext } from "./OrdersUIContext";

export function OrdersCard() {
  const ordersUIContext = useOrdersUIContext();
  const ordersUIProps = useMemo(() => {
    return {
      ids: ordersUIContext.ids,
      newOrderButtonClick: ordersUIContext.newOrderButtonClick,
    };
  }, [ordersUIContext]);

  return (
    <Card>
      {/* <CardHeader title="Orders list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={ordersUIProps.newOrderButtonClick}
          >
            New Order
          </button>
        </CardHeaderToolbar>
      </CardHeader> */}
      <CardBody>
        <OrdersFilter />
        {ordersUIProps.ids.length > 0 && <OrdersGrouping />}
        <OrdersTable />
      </CardBody>
    </Card>
  );
}
