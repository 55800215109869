import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  // CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { StrategiesFilter } from "./strategies-filter/StrategiesFilter";
import { StrategiesTable } from "./strategies-table/StrategiesTable";
import { StrategiesGrouping } from "./strategies-grouping/StrategiesGrouping";
import { useStrategiesUIContext } from "./StrategiesUIContext";

export function StrategiesCard() {
  const strategiesUIContext = useStrategiesUIContext();
  const strategiesUIProps = useMemo(() => {
    return {
      ids: strategiesUIContext.ids,
      newStrategyButtonClick: strategiesUIContext.newStrategyButtonClick,
    };
  }, [strategiesUIContext]);

  return (
    <Card>
      <CardHeader>
        <div
          className="row"
          style={{
            width: "100%",
            justifyContent: "flex-end",
            height: "50%",
            marginTop: "15px",
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={strategiesUIProps.newStrategyButtonClick}
          >
            New Strategy
          </button>
        </div>
      </CardHeader>
      <CardBody>
        <StrategiesFilter />
        {strategiesUIProps.ids.length > 0 && <StrategiesGrouping />}
        <StrategiesTable />
      </CardBody>
    </Card>
  );
}
