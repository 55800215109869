import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StrategyStatusCssClasses } from "../StrategiesUIHelpers";
import * as actions from "../../../../_redux/strategies/strategiesActions";
import { useStrategiesUIContext } from "../StrategiesUIContext";

const selectedStrategies = (entities, ids) => {
  const _strategies = [];
  ids.forEach((id) => {
    const strategy = entities.find((el) => el.id === id);
    if (strategy) {
      _strategies.push(strategy);
    }
  });
  return _strategies;
};

export function StrategiesUpdateStateDialog({ show, onHide }) {
  // Strategies UI Context
  const strategiesUIContext = useStrategiesUIContext();
  const strategiesUIProps = useMemo(() => {
    return {
      ids: strategiesUIContext.ids,
      setIds: strategiesUIContext.setIds,
      queryParams: strategiesUIContext.queryParams,
    };
  }, [strategiesUIContext]);

  // Strategies Redux state
  const { strategies, isLoading } = useSelector(
    (state) => ({
      strategies: selectedStrategies(state.strategies.entities, strategiesUIProps.ids),
      isLoading: state.strategies.actionsLoading,
    }),
    shallowEqual,
  );

  // if !id we should close modal
  useEffect(() => {
    if (!strategiesUIProps.ids || strategiesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategiesUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for update strategies status by selected ids
    dispatch(actions.updateStrategiesStatus(strategiesUIProps.ids, status)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchStrategies(strategiesUIProps.queryParams)).then(() => {
        // clear selections list
        strategiesUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Status has been updated for selected strategies
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {/*begin::Loading*/}
        {isLoading && (
          <div className="overlay-layer">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        )}
        {/*end::Loading*/}

        <div className="timeline timeline-5 mt-3">
          {strategies.map((strategy) => (
            <div
              className="timeline-item align-items-start"
              key={`strategiesUpdate${strategy.id}`}
            >
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i
                  className={`fa fa-genderless text-${
                    StrategyStatusCssClasses[strategy.status]
                  } icon-xxl`}
                />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span
                  className={`label label-lg label-light-${
                    StrategyStatusCssClasses[strategy.status]
                  } label-inline`}
                >
                  ID: {strategy.id}
                </span>
                <span className="ml-3">
                  {strategy.lastName}, {strategy.firstName}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <select
            className="form-control"
            value={status}
            onChange={(e) => setStatus(+e.target.value)}
          >
            <option value="0">Suspended</option>
            <option value="1">Active</option>
            <option value="2">Pending</option>
          </select>
        </div>
        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate mr-3"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            Update Status
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
