import React from "react";
import { Route } from "react-router-dom";
import { PaymentsLoadingDialog } from "./payments-loading-dialog/PaymentsLoadingDialog";
import { PaymentEditDialog } from "./payment-edit-dialog/PaymentEditDialog";
import { PaymentDeleteDialog } from "./payment-delete-dialog/PaymentDeleteDialog";
import { PaymentsDeleteDialog } from "./payments-delete-dialog/PaymentsDeleteDialog";
import { PaymentsFetchDialog } from "./payments-fetch-dialog/PaymentsFetchDialog";
import { PaymentsUpdateStateDialog } from "./payments-update-status-dialog/PaymentsUpdateStateDialog";
import { PaymentsUIProvider } from "./PaymentsUIContext";
import { PaymentsCard } from "./PaymentsCard";

export function PaymentsPage({ history }) {
  const paymentsUIEvents = {
    newPaymentButtonClick: () => {
      history.push("/payments/new");
    },
    openEditPaymentDialog: (id) => {
      history.push(`/payments/${id}/edit`);
    },
    openDeletePaymentDialog: (id) => {
      history.push(`/payments/${id}/delete`);
    },
    openDeletePaymentsDialog: () => {
      history.push(`/payments/deletePayments`);
    },
    openFetchPaymentsDialog: () => {
      history.push(`/payments/fetch`);
    },
    openUpdatePaymentsStatusDialog: () => {
      history.push("/payments/updateStatus");
    },
  };

  return (
    <PaymentsUIProvider paymentsUIEvents={paymentsUIEvents}>
      <PaymentsLoadingDialog />
      <Route path="/payments/new">
        {({ history, match }) => (
          <PaymentEditDialog
            show={match != null}
            onHide={() => {
              history.push("/payments");
            }}
          />
        )}
      </Route>
      <Route path="/payments/:id/edit">
        {({ history, match }) => (
          <PaymentEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/payments");
            }}
          />
        )}
      </Route>
      <Route path="/payments/deletePayments">
        {({ history, match }) => (
          <PaymentsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/payments");
            }}
          />
        )}
      </Route>
      <Route path="/payments/:id/delete">
        {({ history, match }) => (
          <PaymentDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/payments");
            }}
          />
        )}
      </Route>
      <Route path="/payments/fetch">
        {({ history, match }) => (
          <PaymentsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/payments");
            }}
          />
        )}
      </Route>
      <Route path="/payments/updateStatus">
        {({ history, match }) => (
          <PaymentsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/payments");
            }}
          />
        )}
      </Route>
      <PaymentsCard />
    </PaymentsUIProvider>
  );
}
