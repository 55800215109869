// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/transactions/transactionsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../TransactionsUIHelpers";
// import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useTransactionsUIContext } from "../TransactionsUIContext";
import { useParams } from "react-router-dom";
import moment from "moment";

export function TransactionsTable() {
  // Transactions UI Context
  const transactionsUIContext = useTransactionsUIContext();
  let { id } = useParams();
  const transactionsUIProps = useMemo(() => {
    return {
      ids: transactionsUIContext.ids,
      setIds: transactionsUIContext.setIds,
      queryParams: transactionsUIContext.queryParams,
      setQueryParams: transactionsUIContext.setQueryParams,
      openEditTransactionDialog:
        transactionsUIContext.openEditTransactionDialog,
      openDeleteTransactionDialog:
        transactionsUIContext.openDeleteTransactionDialog,
    };
  }, [transactionsUIContext]);

  // Getting curret state of transactions list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.transactions }),
    shallowEqual,
  );
  // console.log(
  //   id,
  //   "dispatch(actions.fetchTransactions(transactionsUIProps.queryParams))",
  // );
  const { totalCount, entities, listLoading } = currentState;
  // Transactions Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    transactionsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchTransactions(id, transactionsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "userName",
      text: "User Name ",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "userEmail",
      text: "User Email ",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "strategyName",
      text: "Strategy",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "exchange",
      text: "Exchange",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "pair",
      text: "Pair",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => moment(cell).format("MM/DD/YY - HH:MM"),
    },
    {
      dataField: "price",
      text: "Price",
      sort: false,
      sortCaret: sortCaret,
      formatter: (value, row) => (
        <>
          ${" "}
          {parseFloat(value)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
        </>
      ),
    },
    {
      dataField: "baseAmount",
      text: "Filled",
      sort: false,
      sortCaret: sortCaret,
      formatter: (value, row) => (
        <>
          {" "}
          {parseFloat(value)
            .toFixed(6)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
        </>
      ),
    },
    {
      dataField: "profit",
      text: "STA %",
      sort: false,
      sortCaret: sortCaret,
      formatter: (value, row) =>
        row.side === "SELL" ? (
          <>
            {parseFloat(value)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
            %
          </>
        ) : (
          "N/A"
        ),
    },
    {
      dataField: "profitAmount",
      text: "STA",
      sort: false,
      sortCaret: sortCaret,
      formatter: (value, row) =>
        row.side === "SELL" ? (
          <>
            $
            {parseFloat(value)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
          </>
        ) : (
          "N/A"
        ),
    },
    {
      dataField: "quoteAmount",
      text: "Total",
      sort: false,
      sortCaret: sortCaret,
      formatter: (value, row) => (
        <>
          {" "}
          {parseFloat(value)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
        </>
      ),
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "side",
      text: "Side",
      sort: false,
      sortCaret: sortCaret,
    },

    {
      dataField: "status",
      text: "Status",
      sort: false,
      sortCaret: sortCaret,
      formatter: (cell, row) => (
        <>
          {cell === "failure" ? (
            <div
              className="f-dot-completed"
              style={{ maxHeight: "15px", maxWidth: "15px" }}
            ></div>
          ) : (
            <div
              className="f-dot"
              style={{ maxHeight: "15px", maxWidth: "15px" }}
            ></div>
          )}
        </>
      ),
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: transactionsUIProps.queryParams.pageSize,
    page: transactionsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  transactionsUIProps.setQueryParams,
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: transactionsUIProps.ids,
                  setIds: transactionsUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
