import React from "react";
import { Route } from "react-router-dom";
import { OrdersLoadingDialog } from "./orders-loading-dialog/OrdersLoadingDialog";
import { OrderEditDialog } from "./order-edit-dialog/OrderEditDialog";
import { OrderDeleteDialog } from "./order-delete-dialog/OrderDeleteDialog";
import { OrdersDeleteDialog } from "./orders-delete-dialog/OrdersDeleteDialog";
import { OrdersFetchDialog } from "./orders-fetch-dialog/OrdersFetchDialog";
import { OrdersUpdateStateDialog } from "./orders-update-status-dialog/OrdersUpdateStateDialog";
import { OrdersUIProvider } from "./OrdersUIContext";
import { OrdersCard } from "./OrdersCard";

export function OrdersPage({ history }) {
  const ordersUIEvents = {
    newOrderButtonClick: () => {
      history.push("/orders/new");
    },
    openEditOrderDialog: (id) => {
      history.push(`/orders/${id}/edit`);
    },
    openDeleteOrderDialog: (id) => {
      history.push(`/orders/${id}/delete`);
    },
    openDeleteOrdersDialog: () => {
      history.push(`/orders/deleteOrders`);
    },
    openFetchOrdersDialog: () => {
      history.push(`/orders/fetch`);
    },
    openUpdateOrdersStatusDialog: () => {
      history.push("/orders/updateStatus");
    },
  };

  return (
    <OrdersUIProvider ordersUIEvents={ordersUIEvents}>
      <OrdersLoadingDialog />
      <Route path="/orders/new">
        {({ history, match }) => (
          <OrderEditDialog
            show={match != null}
            onHide={() => {
              history.push("/orders");
            }}
          />
        )}
      </Route>
      <Route path="/orders/:id/edit">
        {({ history, match }) => (
          <OrderEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/orders");
            }}
          />
        )}
      </Route>
      <Route path="/orders/deleteOrders">
        {({ history, match }) => (
          <OrdersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/orders");
            }}
          />
        )}
      </Route>
      <Route path="/orders/:id/delete">
        {({ history, match }) => (
          <OrderDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/orders");
            }}
          />
        )}
      </Route>
      <Route path="/orders/fetch">
        {({ history, match }) => (
          <OrdersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/orders");
            }}
          />
        )}
      </Route>
      <Route path="/orders/updateStatus">
        {({ history, match }) => (
          <OrdersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/orders");
            }}
          />
        )}
      </Route>
      <OrdersCard />
    </OrdersUIProvider>
  );
}
