import axios from "axios";

export const CUSTOMERS_URL = "/admin/payments";

// CREATE =>  POST: add a new payment to the server
export function createPayment(payment) {
  return axios.post(CUSTOMERS_URL, { payment });
}

// READ
export function getAllPayments() {
  return axios.get(CUSTOMERS_URL);
}

export function getPaymentById(paymentId) {
  return axios.get(`${CUSTOMERS_URL}/${paymentId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPayments(queryParams) {
  return axios.get(`${CUSTOMERS_URL}`, { queryParams });
}

// UPDATE => PUT: update the payment on the server
export function updatePayment(payment) {
  return axios.put(`${CUSTOMERS_URL}/${payment.id}`, { payment });
}

// UPDATE Status
export function updateStatusForPayments(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForPayments`, {
    ids,
    status,
  });
}

// DELETE => delete the payment from the server
export function deletePayment(paymentId) {
  return axios.delete(`${CUSTOMERS_URL}/${paymentId}`);
}

// DELETE Payments by ids
export function deletePayments(ids) {
  return axios.post(`${CUSTOMERS_URL}/deletePayments`, { ids });
}
