// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
// import { TextArea } from "../../../_metronic/_partials/controls";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { ResetPassword } from "../../modules/Auth/pages/ResetPassword";
import { Configurations } from "./configurations";
// Validation schema

const initSetting = {};

const SettingView = ({ actionsLoading }) => {
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [openConfigurationDialog, setOpenComfigurationDialog] = useState(false);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initSetting}
        onSubmit={(values) => {
          //   saveInvoice();
          // console.log(values);
        }}
      >
        <Card style={{ width: "50%" }}>
          <CardBody>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="row">
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate f-button mx-3 "
                      onClick={() => {
                        setOpenResetPasswordDialog(true);
                      }}
                    >
                      <span className="fas fa-lock mr-2"></span>
                      Reset Password
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate f-button mx-3 "
                      onClick={() => {
                        setOpenComfigurationDialog(true);
                      }}
                    >
                      <span className="fas fa-wrench mr-2"></span>
                      Configurations
                    </button>
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </CardBody>
        </Card>
      </Formik>
      <ResetPassword
        openResetPasswordDialog={openResetPasswordDialog}
        setOpenResetPasswordDialog={setOpenResetPasswordDialog}
      />
      <Configurations
        setOpenComfigurationDialog={setOpenComfigurationDialog}
        openConfigurationDialog={openConfigurationDialog}
      />
    </>
  );
};
export default SettingView;
