import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./StrategiesUIHelpers";

const StrategiesUIContext = createContext();

export function useStrategiesUIContext() {
  return useContext(StrategiesUIContext);
}

export const StrategiesUIConsumer = StrategiesUIContext.Consumer;

export function StrategiesUIProvider({ strategiesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initStrategy = {
    id: undefined,
    name: "",
    displayPicture: "",
    description: "",
    url: "",
    Net_Profit: "",
    Net_Profit_Percent: "",
    Total_Closed_Trades: "",
    Percent_Profitable: "",
    Profit_Factor: "",
    Max_Drawdown: "",
    Max_Drawdown_Percent: "",
    Avg_Trade: "",
    Avg_Trade_Percent: "",
    Avg_Bars_Trades: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initStrategy,
    newStrategyButtonClick: strategiesUIEvents.newStrategyButtonClick,
    openEditStrategyDialog: strategiesUIEvents.openEditStrategyDialog,
    openDeleteStrategyDialog: strategiesUIEvents.openDeleteStrategyDialog,
    openDeleteStrategiesDialog: strategiesUIEvents.openDeleteStrategiesDialog,
    openFetchStrategiesDialog: strategiesUIEvents.openFetchStrategiesDialog,
    openUpdateStrategiesStatusDialog:
      strategiesUIEvents.openUpdateStrategiesStatusDialog,
    openViewStrategyDialog: strategiesUIEvents.openViewStrategyDialog,
  };

  return (
    <StrategiesUIContext.Provider value={value}>
      {children}
    </StrategiesUIContext.Provider>
  );
}
