import axios from "axios";

export const CUSTOMERS_URL = "/admin/orders";

// CREATE =>  POST: add a new order to the server
export function createOrder(order) {
  return axios.post(CUSTOMERS_URL, { order });
}

// READ
export function getAllOrders() {
  return axios.get(CUSTOMERS_URL);
}

export function getOrderById(orderId) {
  return axios.get(`${CUSTOMERS_URL}/${orderId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findOrders(queryParams) {
  return axios.get(
    `${CUSTOMERS_URL}/${queryParams?.pageNumber}/${queryParams?.pageSize}`,
    { queryParams },
  );
}

// UPDATE => PUT: update the order on the server
export function updateOrder(order) {
  return axios.put(`${CUSTOMERS_URL}/${order.id}`, { order });
}

// UPDATE Status
export function updateStatusForOrders(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForOrders`, {
    ids,
    status,
  });
}

// DELETE => delete the order from the server
export function deleteOrder(orderId) {
  return axios.delete(`${CUSTOMERS_URL}/${orderId}`);
}

// DELETE Orders by ids
export function deleteOrders(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteOrders`, { ids });
}
