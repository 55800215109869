import * as requestFromServer from "./strategiesCrud";
import { strategiesSlice, callTypes } from "./strategiesSlice";
import TableUtils from "../utils/tableUtils";

const { actions } = strategiesSlice;

export const fetchStrategies = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findStrategies(queryParams)
    .then((response) => {
      const { data } = response.data;
      const tableUtils = new TableUtils();
      let refractoredStrategies = data
        .filter((item) => item.role !== "admin")
        .map((item, index) => ({
          ...item,
          _id: item._id,
          id: item?.id,
          strategyName: item?.StrategyName,
          netProfit: item?.NetProfit,
        }));
      const filterdUsers = tableUtils.baseFilter(
        refractoredStrategies,
        queryParams,
      );
      dispatch(
        actions.strategiesFetched({
          totalCount: filterdUsers.totalCount,
          entities: [...filterdUsers.entities],
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find strategies";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchStrategy = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.strategyFetched({ strategyForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getStrategyById(id)
    .then((response) => {
      const strategy = response.data.data;
      dispatch(actions.strategyFetched({ strategyForEdit: strategy }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find strategy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteStrategy = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteStrategy(id)
    .then((response) => {
      dispatch(actions.strategyDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete strategy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createStrategy = (strategyForCreation, queryParams) => (
  dispatch,
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createStrategy(strategyForCreation)
    .then((response) => {
      const { strategy } = response.data;
      dispatch(actions.strategyCreated({ strategy }));
      dispatch(actions.fetchStrategies(queryParams));
    })
    .catch((error) => {
      error.clientMessage = "Can't create strategy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateStrategy = (strategy, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStrategy(strategy)
    .then(() => {
      dispatch(actions.strategyUpdated({ strategy }));
      dispatch(actions.fetchStrategies(queryParams));
    })
    .catch((error) => {
      error.clientMessage = "Can't update strategy";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateStrategiesStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForStrategies(ids, status)
    .then(() => {
      dispatch(actions.strategiesStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update strategies status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteStrategies = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteStrategies(ids)
    .then(() => {
      dispatch(actions.strategiesDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete strategies";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
