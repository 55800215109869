// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import {
  Input,
  // Select,
  // DatePickerField,
} from "../../../../_metronic/_partials/controls";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Card,
  CardBody,
  // CardHeader,
  // CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

import { useDispatch } from "react-redux";

import * as actions from "../../../../_redux/users/usersActions";

import { Switch, FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Validation schema

export function UserEditForm({
  saveUser,
  user,
  actionsLoading,
  onHide,
  view,
  queryParams,
}) {
  // console.log(user, "user");
  const { id } = useParams();
  const RedSwitch = withStyles({
    switchBase: {
      color: "primary",
      "&$checked": {
        color: "primary",
      },
      "&$checked + $track": {
        backgroundColor: "primary",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const dispatch = useDispatch();
  console.log(user, "user");
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...user, isDisabled: !user.isDisabled }}
        onSubmit={(values) => {
          // saveUser(values);
          // console.log(Number(values.commissionDue));
          let userDetailsAndProfit;
          // eslint-disable-next-line
          userDetailsAndProfit = {
            commissionPercent: Number(values.commissionPercent),
            isDisabled: !values.isDisabled,
            commissionThreshold: values.commissionThreshold,
          };
          console.log(userDetailsAndProfit, "userDetailsAndProfit");
          axios
            .patch(`/admin/updatecommission/${id}`, userDetailsAndProfit)
            .then(
              (response) => onHide(),
              dispatch(actions.fetchUsers(queryParams)),
            );
        }}
      >
        {({ handleSubmit, values, setValues }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-4">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Last Name */}
                  <div className="col-lg-4">
                    <Field
                      type="email"
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Login */}
                  <div className="col-lg-4">
                    <Field
                      name="exchangesCount"
                      component={Input}
                      placeholder="Exchanges"
                      label="Exchanges"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="commissionDue"
                      component={Input}
                      placeholder="Payable"
                      label="Payable"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Date of birth */}
                  <div className="col-lg-4">
                    <Field
                      name="totalProfit"
                      component={Input}
                      placeholder=" Total Profit"
                      label="Total Profit"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* IP Address */}
                  <div className="col-lg-4" style={{ marginTop: "27px" }}>
                    <FormControlLabel
                      control={
                        <RedSwitch
                          value="isDisabled"
                          checked={values.isDisabled || false}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              isDisabled: e.target.checked,
                            });
                          }}
                        />
                      }
                      label="Status"
                      labelPlacement="start"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* Gender */}
                  <div className="col-lg-4">
                    <Field
                      name="strategiesCount"
                      component={Input}
                      placeholder="Strategies "
                      label="Strategies"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Type */}
                  <div className="col-lg-4">
                    <Field
                      name="verified"
                      component={Input}
                      placeholder="Verified "
                      label="Verified"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="commissionPercent"
                      component={Input}
                      placeholder="Percentage Commission"
                      label="Percentage Commission"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* Gender */}

                  <div className="col-lg-4">
                    <Field
                      name="commissionThreshold"
                      component={Input}
                      placeholder="Minimum Payable"
                      label="Minimum Payable"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
            <div className="row">
              <Card
                className="shadow p-3 mb-5 bg-body rounded"
                style={{ width: "30%" }}
              >
                <CardBody>
                  <div className="col-lg-6" style={{ marginLeft: "20%" }}>
                    <div
                      className="row"
                      style={{
                        justifyContent: "center",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Strategy Name
                    </div>
                    {user?.strategiesDetails?.map((item, index) => {
                      return (
                        <>
                          <Card className="shadow p-3 mb-5 bg-body rounded">
                            <CardBody>
                              <div
                                className="row"
                                key={index}
                                style={{
                                  justifyContent: "center",
                                  fontWeight: "500",
                                }}
                              >
                                {item?.strategyName}
                              </div>
                              <div
                                key={index + 1}
                                className="row"
                                style={{
                                  justifyContent: "center",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                Exchanges
                              </div>
                              {item.exchanges.map((items, index) => {
                                return (
                                  <>
                                    <div
                                      key={index + 3}
                                      className="row"
                                      style={{
                                        justifyContent: "center",
                                      }}
                                    >
                                      {items}
                                    </div>
                                  </>
                                );
                              })}
                            </CardBody>
                          </Card>
                        </>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
              <Card
                className="shadow p-3 mb-5 bg-body rounded"
                style={{ width: "30%", marginLeft: "20px" }}
              >
                <CardBody>
                  <div className="col-lg-6" style={{ marginLeft: "20%" }}>
                    <div
                      className="row"
                      style={{
                        justifyContent: "center",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Exchange Name
                    </div>
                    {user?.exchangesDetails?.map((item, index) => {
                      return (
                        <>
                          <div
                            key={index + 4}
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "10px",
                            }}
                          >
                            {item}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
