import React from "react";
import { Route, Switch } from "react-router-dom";
import { TransactionsLoadingDialog } from "./transactions-loading-dialog/TransactionsLoadingDialog";
import { TransactionEditDialog } from "./transaction-edit-dialog/TransactionEditDialog";
import { TransactionDeleteDialog } from "./transaction-delete-dialog/TransactionDeleteDialog";
import { TransactionsDeleteDialog } from "./transactions-delete-dialog/TransactionsDeleteDialog";
import { TransactionsFetchDialog } from "./transactions-fetch-dialog/TransactionsFetchDialog";
import { TransactionsUpdateStateDialog } from "./transactions-update-status-dialog/TransactionsUpdateStateDialog";
import { TransactionsUIProvider } from "./TransactionsUIContext";
import { TransactionsCard } from "./TransactionsCard";

export function TransactionsPage({ history }) {
  const transactionsUIEvents = {
    newTransactionButtonClick: () => {
      history.push("/transactions/new");
    },
    openEditTransactionDialog: (id) => {
      history.push(`/transactions/${id}/edit`);
    },
    openDeleteTransactionDialog: (id) => {
      history.push(`/transactions/${id}/delete`);
    },
    openDeleteTransactionsDialog: () => {
      history.push(`/transactions/deleteTransactions`);
    },
    openFetchTransactionsDialog: () => {
      history.push(`/transactions/fetch`);
    },
    openUpdateTransactionsStatusDialog: () => {
      history.push("/transactions/updateStatus");
    },
  };

  return (
    <TransactionsUIProvider transactionsUIEvents={transactionsUIEvents}>
      <TransactionsLoadingDialog />
      <Switch>
        <Route path="/transactions/new">
          {({ history, match }) => (
            <TransactionEditDialog
              show={match != null}
              onHide={() => {
                history.push("/transactions");
              }}
            />
          )}
        </Route>
        <Route path="/transactions/:id/edit">
          {({ history, match }) => (
            <TransactionEditDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/transactions");
              }}
            />
          )}
        </Route>
        <Route path="/transactions">
          <Route path="/transactions/deleteTransactions">
            {({ history, match }) => (
              <TransactionsDeleteDialog
                show={match != null}
                onHide={() => {
                  history.push("/transactions");
                }}
              />
            )}
          </Route>
          <Route path="/transactions/:id/delete">
            {({ history, match }) => (
              <TransactionDeleteDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/transactions");
                }}
              />
            )}
          </Route>
          <Route path="/transactions/fetch">
            {({ history, match }) => (
              <TransactionsFetchDialog
                show={match != null}
                onHide={() => {
                  history.push("/transactions");
                }}
              />
            )}
          </Route>
          <Route path="/transactions/updateStatus">
            {({ history, match }) => (
              <TransactionsUpdateStateDialog
                show={match != null}
                onHide={() => {
                  history.push("/transactions");
                }}
              />
            )}
          </Route>
          <TransactionsCard />
        </Route>
      </Switch>
    </TransactionsUIProvider>
  );
}
