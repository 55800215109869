import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { UsersPage } from "./pages/users/UsersPage";
import { OrdersPage } from "./pages/orders/OrdersPage";
import { StrategiesPage } from "./pages/strategies/StrategiesPage";
import { PaymentsPage } from "./pages/payments/PaymentsPage";
import { SettingsPage } from "./pages/settings/SettingsPage";
import { TransactionsPage } from "./pages/transactions/TransactionsPage";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage"),
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage"),
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage"),
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/strategies" component={StrategiesPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/payments" component={PaymentsPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/transactions" component={TransactionsPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
