import axios from "axios";
export const CUSTOMERS_URL = "/admin/getusertransaction";

// CREATE =>  POST: add a new transaction to the server
export function createTransaction(transaction) {
  return axios.post(CUSTOMERS_URL, { transaction });
}

// READ
export function getAllTransactions() {
  return axios.get(CUSTOMERS_URL);
}

export function getTransactionById(transactionId) {
  return axios.get(`/admin/gettransaction/${transactionId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findTransactions(id, queryParams) {
  return axios.get(
    `/admin/getusertransaction/${id}/${queryParams?.pageNumber}/${queryParams?.pageSize}`,
    {
      queryParams,
    },
  );
}

// UPDATE => PUT: update the transaction on the server
export function updateTransaction(transaction) {
  return axios.put(`${CUSTOMERS_URL}/${transaction.id}`, { transaction });
}

// UPDATE Status
export function updateStatusForTransactions(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForTransactions`, {
    ids,
    status,
  });
}

// DELETE => delete the transaction from the server
export function deleteTransaction(transactionId) {
  return axios.delete(`${CUSTOMERS_URL}/${transactionId}`);
}

// DELETE Transactions by ids
export function deleteTransactions(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteTransactions`, { ids });
}
