import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { StrategyStatusCssClasses } from "../StrategiesUIHelpers";
import { useStrategiesUIContext } from "../StrategiesUIContext";

const selectedStrategies = (entities, ids) => {
  const _strategies = [];
  ids.forEach((id) => {
    const strategy = entities.find((el) => el.id === id);
    if (strategy) {
      _strategies.push(strategy);
    }
  });
  return _strategies;
};

export function StrategiesFetchDialog({ show, onHide }) {
  // Strategies UI Context
  const strategiesUIContext = useStrategiesUIContext();
  const strategiesUIProps = useMemo(() => {
    return {
      ids: strategiesUIContext.ids,
    };
  }, [strategiesUIContext]);

  // Strategies Redux state
  const { strategies } = useSelector(
    (state) => ({
      strategies: selectedStrategies(
        state.strategies.entities,
        strategiesUIProps.ids
      ),
    }),
    shallowEqual
  );

  // if strategies weren't selected we should close modal
  useEffect(() => {
    if (!strategiesUIProps.ids || strategiesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategiesUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline timeline-5 mt-3">
          {strategies.map((strategy) => (
            <div className="timeline-item align-items-start" key={`id${strategy.id}`}>
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i
                  className={`fa fa-genderless text-${
                    StrategyStatusCssClasses[strategy.status]
                  } icon-xxl`}
                />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span
                    className={`label label-lg label-light-${
                      StrategyStatusCssClasses[strategy.status]
                    } label-inline`}
                  >
                    ID: {strategy.id}
                </span>
                <span className="ml-3">{strategy.lastName}, {strategy.firstName}</span>                
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
