import axios from "axios";

export const CUSTOMERS_URL = "/admin/strategies";

// CREATE =>  POST: add a new strategy to the server
export function createStrategy(strategy) {
  console.log(strategy, "strategy calling");
  return axios.post("/admin/strategy/add", strategy);
}

// READ
export function getAllStrategies() {
  return axios.get(CUSTOMERS_URL);
}

export function getStrategyById(strategyId) {
  // console.log(strategyId, "strategyId");
  return axios.get(`/admin/strategyDetails/${strategyId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findStrategies(queryParams) {
  return axios.get(`${CUSTOMERS_URL}`, { queryParams });
}

// UPDATE => PUT: update the strategy on the server
export function updateStrategy(strategy) {
  return axios.patch(`/admin/strategy/update/${strategy._id}`, strategy);
}

// UPDATE Status
export function updateStatusForStrategies(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForStrategies`, {
    ids,
    status,
  });
}

// DELETE => delete the strategy from the server
export function deleteStrategy(strategyId) {
  return axios.delete(`/admin/strategy/delete/${strategyId}`);
}

// DELETE Strategies by ids
export function deleteStrategies(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteStrategies`, { ids });
}
