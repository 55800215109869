import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./TransactionsUIHelpers";

const TransactionsUIContext = createContext();

export function useTransactionsUIContext() {
  return useContext(TransactionsUIContext);
}

export const TransactionsUIConsumer = TransactionsUIContext.Consumer;

export function TransactionsUIProvider({ transactionsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initTransaction = {
    id: undefined,
    name: "",
    verified: "",
    email: "",
    status: "",
    exchangesCount: "",
    totalProfit: "",
    commissionDue: "",
    strategiesCount: "",
    type: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initTransaction,
  };

  return (
    <TransactionsUIContext.Provider value={value}>
      {children}
    </TransactionsUIContext.Provider>
  );
}
