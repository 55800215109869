// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
// Validation schema
const ResetPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(20, "Maximum 20 symbols")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "Must be contain at least 1 uper case alphabet and 1 number",
    )
    .required("Firstname is required"),
  newPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(20, "Maximum 20 symbols")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "Must be contain at least 1 uper case alphabet and 1 number",
    )
    .required("Lastname is required"),
  confirmPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(20, "Maximum 20 symbols")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      "Must be contain at least 1 uper case alphabet and 1 number",
    )
    .required("Lastname is required"),
});
const initResetPassword = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export function ResetPassword({
  openResetPasswordDialog,
  setOpenResetPasswordDialog,
  actionsLoading,
}) {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  // console.log(user);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initResetPassword}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => {
          const resetPassword = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          };
          if (values?.newPassword === values?.confirmPassword && user._id) {
            axios
              .post(`/admin/auth/change-password/${user._id}`, resetPassword)
              .then((res) => {
                console.log(res);
                history.push("/logout");
                toast.success("Password changed");
              })
              .catch((error) => {
                toast.error("Old password is not correct");
              });
          } else {
            toast.error("Password doesn't matched !");
          }
          console.log(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal
              size="md"
              show={openResetPasswordDialog}
              onHide={() => {
                setOpenResetPasswordDialog(false);
              }}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title>Reset Password</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-12">
                      <Field
                        type="password"
                        name="oldPassword"
                        component={Input}
                        placeholder="Old Password"
                        label="Old Password"
                      />
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-12">
                      <Field
                        type="password"
                        name="newPassword"
                        component={Input}
                        placeholder="New Password"
                        label="New Password"
                      />
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-12">
                      <Field
                        type="password"
                        name="confirmPassword"
                        component={Input}
                        placeholder="Confirm Password"
                        label="Confirm Password"
                      />
                    </div>
                    {/* Last Name */}
                  </div>
                  {/* Email */}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={() => {
                    setOpenResetPasswordDialog(false);
                  }}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
      <Toaster position="top-center" />
    </>
  );
}
