// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  // Select,
  // DatePickerField,
} from "../../../../_metronic/_partials/controls";

// Validation schema
const StrategyEditSchema = Yup.object().shape({
  name: Yup.string().required(" Name is required"),
  description: Yup.string().required(" Description is required"),
  Net_Profit: Yup.string().matches(
    /[+-]?([0-9._%$+-]*[.])?[0-9._%$+-]+$/,
    "Only Numbers",
  ),
  Net_Profit_Percent: Yup.string().matches(
    /[+-]?([0-9._%$+-]*[.])?[0-9._%$+-]+$/,
    "Only Numbers",
  ),
  Total_Closed_Trades: Yup.string().matches(
    /[+-]?([0-9]*[.])?[0-9]+$/,
    "Only Numbers",
  ),
  Percent_Profitable: Yup.string().matches(
    /[+-]?([0-9._%$+-]*[.])?[0-9._%$+-]+$/,
    "Only Numbers",
  ),
  Profit_Factor: Yup.string().matches(
    /[+-]?([0-9._%$+-]*[.])?[0-9._%+$-]+$/,
    "Only Numbers",
  ),
  Max_Drawdown: Yup.string().matches(
    /[+-]?([0-9._%$+-]*[.])?[0-9._%$+-]+$/,
    "Only Numbers",
  ),
  Max_Drawdown_Percent: Yup.string().matches(
    /[+-]?([0-9._%$+-]*[.])?[0-9._%+$-]+$/,
    "Only Numbers",
  ),
  Avg_Trade: Yup.string().matches(
    /[+-]?([0-9._%$+-]*[.])?[0-9._%+$-]+$/,
    "Only Numbers",
  ),
  Avg_Trade_Percent: Yup.string().matches(
    /[+-]?([0-9._%+$-]*[.])?[0-9._%$+-]+$/,
    "Only Numbers",
  ),
  Avg_Bars_Trades: Yup.string().matches(/^[0-9._%+-]+$/, "Only Numbers"),
  url: Yup.string(),
  displayPicture: Yup.string().required(" Picture is required"),
});

export function StrategyEditForm({
  saveStrategy,
  strategy,
  actionsLoading,
  onHide,
  view,
}) {
  // console.log(view);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={strategy}
        validationSchema={StrategyEditSchema}
        onSubmit={(values) => {
          saveStrategy(values);
          console.log(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                {view ? (
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-12">
                      <img
                        style={{ borderRadius: "10px", maxWidth: "30%" }}
                        src={strategy?.displayPicture}
                        alt=" Invalid URL..."
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-4">
                    <Field
                      name="name"
                      component={Input}
                      placeholder=" Name"
                      label="Name"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Last Name */}
                  <div className="col-lg-4">
                    <Field
                      name="description"
                      component={Input}
                      placeholder=" Description"
                      label=" Description"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Login */}
                  <div className="col-lg-3">
                    <Field
                      name="Net_Profit"
                      component={Input}
                      placeholder="Profit"
                      label="Profit"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="col-lg-1">
                    {" "}
                    <span>
                      <i
                        className="fas fa-dollar-sign"
                        style={{ marginTop: "36px", color: "#24ACF2" }}
                      ></i>
                    </span>
                  </div>
                </div>
                {/* Email */}
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="Net_Profit_Percent"
                      component={Input}
                      placeholder="Profit Percent"
                      label="Profit Percent"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Date of birth */}
                  <div className="col-lg-4">
                    <Field
                      name="Total_Closed_Trades"
                      component={Input}
                      placeholder="Closed Trades"
                      label=" Closed Trades"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* IP Address */}
                  <div className="col-lg-3">
                    <Field
                      name="Percent_Profitable"
                      component={Input}
                      placeholder=" Percent Profitable"
                      label=" Percent Profitable"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="col-lg-1">
                    {" "}
                    <span>
                      <i
                        className="fas fa-percent "
                        style={{ marginTop: "36px", color: "#24ACF2" }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  {/* Gender */}
                  <div className="col-lg-4">
                    <Field
                      name="Profit_Factor"
                      component={Input}
                      placeholder="Profit Factor"
                      label="  Profit Factor"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Type */}
                  <div className="col-lg-4">
                    <Field
                      name="Max_Drawdown"
                      component={Input}
                      placeholder="  Max Drawdown"
                      label=" Max Drawdown"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="Max_Drawdown_Percent"
                      component={Input}
                      placeholder=" Max Drawdown Percent"
                      label="   Max Drawdown Percent"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* Gender */}

                  {/* Type */}
                  <div className="col-lg-4">
                    <Field
                      name="Avg_Trade"
                      component={Input}
                      placeholder="   Average Trade"
                      label=" Average Trade"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="Avg_Trade_Percent"
                      component={Input}
                      placeholder="  Trade Percent"
                      label="   Trade Percent"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Type */}
                  <div className="col-lg-4">
                    <Field
                      name="Avg_Bars_Trades"
                      component={Input}
                      placeholder="   Bars  Trades"
                      label=" Bars Trades"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* Gender */}

                  {/* Type */}
                  {view ? (
                    ""
                  ) : (
                    <div className="col-lg-4">
                      <Field
                        name="displayPicture"
                        component={Input}
                        placeholder="Display Picture"
                        label=" Display Picture"
                      />
                    </div>
                  )}

                  <div className="col-lg-4">
                    <Field
                      name="url"
                      component={Input}
                      placeholder="  URL"
                      label="   URL"
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  {/* Type */}
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              {view ? (
                ""
              ) : (
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              )}
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
