import React, { useEffect, useMemo } from "react";
// import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/users/usersActions";
import { UserEditDialogHeader } from "./UserEditDialogHeader";
import { UserEditForm } from "./UserEditForm";
import { useUsersUIContext } from "../UsersUIContext";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";

export function UserEditDialog({ id, show, onHide, view }) {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      initUser: usersUIContext.initUser,

      queryParams: usersUIContext?.queryParams,
    };
  }, [usersUIContext]);

  // Users Redux state
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit,
    }),
    shallowEqual,
  );

  useEffect(() => {
    // server call for getting User by id
    dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  // server request for saving user
  const saveUser = (user) => {
    if (!id) {
      // server request for creating user
      dispatch(actions.createUser(user)).then(() => onHide());
    } else {
      // server request for updating user
      dispatch(actions.updateUser(user)).then(() => onHide());
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <UserEditDialogHeader id={id} />
          <UserEditForm
            saveUser={saveUser}
            actionsLoading={actionsLoading}
            user={userForEdit?.data || usersUIProps.initUser}
            onHide={onHide}
            view={view}
            queryParams={usersUIProps.queryParams}
          />
        </CardBody>
      </Card>
    </>
    // </Modal>
  );
}
