import * as requestFromServer from "./transactionsCrud";
import { transactionsSlice, callTypes } from "./transactionsSlice";
import TableUtils from "../utils/tableUtils";
const { actions } = transactionsSlice;

export const fetchTransactions = (id, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTransactions(id, queryParams)
    .then((response) => {
      console.log(response, "==");
      const { orderDetails } = response.data.data;
      const tableUtils = new TableUtils();
      let refractoredTransactions = orderDetails.map((item, index) => ({
        ...item,
        _id: item._id,
        id: index + 1,
        date: item.date,
      }));
      // console.log(refractoredTransactions, "refractoredTransactions");
      const filterdTransactions = tableUtils.baseFilter(
        refractoredTransactions,
        queryParams,
      );
      // console.log(filterdTransactions, "filterdTransactionsManagement");
      dispatch(
        actions.transactionsFetched({
          totalCount: filterdTransactions.totalCount,
          entities: [...filterdTransactions.entities],
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTransaction = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.transactionFetched({ transactionForEdit: undefined }),
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTransactionById(id)
    .then((response) => {
      console.log(response, "response");
      const transaction = response.data;
      dispatch(actions.transactionFetched({ transactionForEdit: transaction }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTransaction = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransaction(id)
    .then((response) => {
      dispatch(actions.transactionDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTransaction = (transactionForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTransaction(transactionForCreation)
    .then((response) => {
      const { transaction } = response.data;
      dispatch(actions.transactionCreated({ transaction }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTransaction = (transaction) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTransaction(transaction)
    .then(() => {
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTransactionsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForTransactions(ids, status)
    .then(() => {
      dispatch(actions.transactionsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update transactions status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTransactions = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransactions(ids)
    .then(() => {
      dispatch(actions.transactionsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete transactions";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
