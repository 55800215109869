export default function setupAxios(axios, store, logout) {
  const onResponseSuccess = (response) => response;
  const onResponseError = (err) => {
    if (!err.status) {
      //   // logout();
      // toast.error("We are having trouble connecting, Please try again later");
    }
    const status = err.status || (err.response ? err.response.status : 0);
    // console.log("API call response : ", status);
    if (status === 401) {
      logout();
    }
    return Promise.reject(err);
  };
  const TIMEOUT = 1 * 60 * 1000;
  axios.defaults.timeout = TIMEOUT;
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

  // console.log(axios.defaults.baseURL, '===-=-===-=-=--=');
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err),
  );
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
}
