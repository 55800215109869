import React from "react";
import { Switch } from "react-router-dom";
import SettingView from "./settingView";

export function SettingsPage({ history }) {
  return (
    <Switch>
      <SettingView />
    </Switch>
  );
}
