import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/payments/paymentsActions";
import { PaymentEditDialogHeader } from "./PaymentEditDialogHeader";
import { PaymentEditForm } from "./PaymentEditForm";
import { usePaymentsUIContext } from "../PaymentsUIContext";

export function PaymentEditDialog({ id, show, onHide }) {
  // Payments UI Context
  const paymentsUIContext = usePaymentsUIContext();
  const paymentsUIProps = useMemo(() => {
    return {
      initPayment: paymentsUIContext.initPayment,
    };
  }, [paymentsUIContext]);

  // Payments Redux state
  const dispatch = useDispatch();
  const { actionsLoading, paymentForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.payments.actionsLoading,
      paymentForEdit: state.payments.paymentForEdit,
    }),
    shallowEqual,
  );

  useEffect(() => {
    // server call for getting Payment by id
    dispatch(actions.fetchPayment(id));
  }, [id, dispatch]);

  // server request for saving payment
  const savePayment = (payment) => {
    if (!id) {
      // server request for creating payment
      dispatch(actions.createPayment(payment)).then(() => onHide());
    } else {
      // server request for updating payment
      dispatch(actions.updatePayment(payment)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <PaymentEditDialogHeader id={id} />
      <PaymentEditForm
        savePayment={savePayment}
        actionsLoading={actionsLoading}
        payment={paymentForEdit || paymentsUIProps.initPayment}
        onHide={onHide}
      />
    </Modal>
  );
}
