import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/strategies/strategiesActions";
import { useStrategiesUIContext } from "../StrategiesUIContext";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

export function StrategiesDeleteDialog({ show, onHide }) {
  // Strategies UI Context
  const strategiesUIContext = useStrategiesUIContext();
  const strategiesUIProps = useMemo(() => {
    return {
      ids: strategiesUIContext.ids,
      setIds: strategiesUIContext.setIds,
      queryParams: strategiesUIContext.queryParams,
    };
  }, [strategiesUIContext]);

  // Strategies Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.strategies.actionsLoading }),
    shallowEqual,
  );

  // if strategies weren't selected we should close modal
  useEffect(() => {
    if (!strategiesUIProps.ids || strategiesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategiesUIProps.ids]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteStrategies = () => {
    // server request for deleting strategy by selected ids
    dispatch(actions.deleteStrategies(strategiesUIProps.ids)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchStrategies(strategiesUIProps.queryParams)).then(() => {
        // clear selections list
        strategiesUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Strategies Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete selected strategies?</span>
        )}
        {isLoading && <span>Strategy are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteStrategies}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
