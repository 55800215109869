import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/orders/ordersActions";
import { useOrdersUIContext } from "../OrdersUIContext";

export function OrderDeleteDialog({ id, show, onHide }) {
  // Orders UI Context
  const ordersUIContext = useOrdersUIContext();
  const ordersUIProps = useMemo(() => {
    return {
      setIds: ordersUIContext.setIds,
      queryParams: ordersUIContext.queryParams,
    };
  }, [ordersUIContext]);

  // Orders Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.orders.actionsLoading }),
    shallowEqual,
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteOrder = () => {
    // server request for deleting order by id
    dispatch(actions.deleteOrder(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchOrders(ordersUIProps.queryParams));
      // clear selections list
      ordersUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Order Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this order?</span>
        )}
        {isLoading && <span>Order is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteOrder}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
