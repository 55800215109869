import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/strategies/strategiesActions";
import { StrategyEditDialogHeader } from "./StrategyEditDialogHeader";
import { StrategyEditForm } from "./StrategyEditForm";
import { useStrategiesUIContext } from "../StrategiesUIContext";

export function StrategyEditDialog({ id, show, onHide, view }) {
  // Strategies UI Context
  const strategiesUIContext = useStrategiesUIContext();
  const strategiesUIProps = useMemo(() => {
    return {
      initStrategy: strategiesUIContext.initStrategy,
      queryParams: strategiesUIContext?.queryParams,
    };
  }, [strategiesUIContext]);

  // Strategies Redux state
  const dispatch = useDispatch();
  const { actionsLoading, strategyForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.strategies.actionsLoading,
      strategyForEdit: state.strategies.strategyForEdit,
    }),
    shallowEqual,
  );

  useEffect(() => {
    // server call for getting Strategy by id
    dispatch(actions.fetchStrategy(id));
  }, [id, dispatch]);

  // server request for saving strategy
  const saveStrategy = (strategy) => {
    if (!id) {
      // server request for creating strategy
      dispatch(
        actions.createStrategy(strategy, strategiesUIProps.queryParams),
      ).then(
        onHide(),
        dispatch(actions.fetchStrategies(strategiesUIProps.queryParams)),
      );
    } else {
      // server request for updating strategy
      dispatch(
        actions.updateStrategy(strategy, strategiesUIProps.queryParams),
      ).then(
        onHide(),
        dispatch(actions.fetchStrategies(strategiesUIProps.queryParams)),
      );
    }
  };
  // console.log(strategyForEdit?.strategy, "strategyForEdit");
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <StrategyEditDialogHeader id={id} view={view} />
      <StrategyEditForm
        saveStrategy={saveStrategy}
        actionsLoading={actionsLoading}
        strategy={strategyForEdit?.strategy || strategiesUIProps.initStrategy}
        onHide={onHide}
        view={view}
      />
    </Modal>
  );
}
