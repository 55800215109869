import * as requestFromServer from "./ordersCrud";
import { ordersSlice, callTypes } from "./ordersSlice";
import TableUtils from "../utils/tableUtils";
import moment from "moment";

const { actions } = ordersSlice;

export const fetchOrders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOrders(queryParams)
    .then((response) => {
      console.log(response);
      // console.log(response);
      const { orderDetails } = response.data.data;
      const tableUtils = new TableUtils();
      let refractoredOrders = orderDetails.map((item, index) => ({
        ...item,
        _id: item.id,
        id: index + 1,
        exchange: item?.exchange,
        status: item?.status,
        date: moment(item.date).format("MM/DD/YYYY"),
      }));
      // console.log(refractoredOrders, "refractoredOrders");
      const filterdUsers = tableUtils.baseFilter(
        refractoredOrders,
        queryParams,
      );
      dispatch(
        actions.ordersFetched({
          totalCount: filterdUsers.totalCount,
          entities: [...filterdUsers.entities],
        }),
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find orders";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOrder = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.orderFetched({ orderForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOrderById(id)
    .then((response) => {
      const order = response.data;
      dispatch(actions.orderFetched({ orderForEdit: order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrder = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrder(id)
    .then((response) => {
      dispatch(actions.orderDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrder = (orderForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOrder(orderForCreation)
    .then((response) => {
      const { order } = response.data;
      dispatch(actions.orderCreated({ order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrder = (order) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOrder(order)
    .then(() => {
      dispatch(actions.orderUpdated({ order }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update order";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOrdersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOrders(ids, status)
    .then(() => {
      dispatch(actions.ordersStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update orders status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOrders = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOrders(ids)
    .then(() => {
      dispatch(actions.ordersDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete orders";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
