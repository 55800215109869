import React, { useEffect, useMemo } from "react";
// import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/transactions/transactionsActions";
import { TransactionEditDialogHeader } from "./TransactionEditDialogHeader";
import { TransactionEditForm } from "./TransactionEditForm";
import { useTransactionsUIContext } from "../TransactionsUIContext";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";

export function TransactionEditDialog({ id, show, onHide }) {
  // Transactions UI Context
  const transactionsUIContext = useTransactionsUIContext();
  const transactionsUIProps = useMemo(() => {
    return {
      initTransaction: transactionsUIContext.initTransaction,
    };
  }, [transactionsUIContext]);

  // Transactions Redux state
  const dispatch = useDispatch();
  const { actionsLoading, transactionForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.transactions.actionsLoading,
      transactionForEdit: state.transactions.transactionForEdit,
    }),
    shallowEqual,
  );

  useEffect(() => {
    // server call for getting Transaction by id
    dispatch(actions.fetchTransaction(id));
  }, [id, dispatch]);

  // server request for saving transaction
  const saveTransaction = (transaction) => {
    if (!id) {
      // server request for creating transaction
      dispatch(actions.createTransaction(transaction)).then(() => onHide());
    } else {
      // server request for updating transaction
      dispatch(actions.updateTransaction(transaction)).then(() => onHide());
    }
  };
  // console.log(transactionForEdit, "transactionForEdit");

  return (
    // <Modal
    //   size="lg"
    //   show={show}
    //   onHide={onHide}
    //   aria-labelledby="example-modal-sizes-title-lg"
    // >
    <>
      <Card>
        <CardBody>
          <TransactionEditDialogHeader id={id} />
          <TransactionEditForm
            saveTransaction={saveTransaction}
            actionsLoading={actionsLoading}
            transaction={transactionForEdit?.data || transactionsUIProps.initTransaction}
            onHide={onHide}
          />
        </CardBody>
      </Card>
    </>
    // </Modal>
  );
}
