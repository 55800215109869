export const StrategyStatusCssClasses = ["danger", "success", "info", ""];
export const StrategyStatusTitles = ["Suspended", "Active", "Pending", ""];
export const StrategyTypeCssClasses = ["success", "primary", ""];
export const StrategyTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "30", value: 30 },
  { text: "40", value: 40 },
];
export const initialFilter = {
  filter: {
    exchange: "",
    status: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 20,
};
