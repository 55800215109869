import React, { useMemo } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { TransactionsFilter } from "./transactions-filter/TransactionsFilter";
import { TransactionsTable } from "./transactions-table/TransactionsTable";
import { TransactionsGrouping } from "./transactions-grouping/TransactionsGrouping";
import { useTransactionsUIContext } from "./TransactionsUIContext";

export function TransactionsCard() {
  const transactionsUIContext = useTransactionsUIContext();
  const transactionsUIProps = useMemo(() => {
    return {
      ids: transactionsUIContext.ids,
      newTransactionButtonClick:
        transactionsUIContext.newTransactionButtonClick,
    };
  }, [transactionsUIContext]);

  return (
    <Card>
      <CardBody>
        <TransactionsFilter />
        {transactionsUIProps.ids.length > 0 && <TransactionsGrouping />}
        <TransactionsTable />
      </CardBody>
    </Card>
  );
}
