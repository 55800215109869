import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { PaymentsFilter } from "./payments-filter/PaymentsFilter";
import { PaymentsTable } from "./payments-table/PaymentsTable";
import { PaymentsGrouping } from "./payments-grouping/PaymentsGrouping";
import { usePaymentsUIContext } from "./PaymentsUIContext";

export function PaymentsCard() {
  const paymentsUIContext = usePaymentsUIContext();
  const paymentsUIProps = useMemo(() => {
    return {
      ids: paymentsUIContext.ids,
      newPaymentButtonClick: paymentsUIContext.newPaymentButtonClick,
    };
  }, [paymentsUIContext]);

  return (
    <Card>
      <CardHeader title="Payments list">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaymentsFilter />
        {paymentsUIProps.ids.length > 0 && <PaymentsGrouping />}
        <PaymentsTable />
      </CardBody>
    </Card>
  );
}
