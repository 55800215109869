import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
const initDashboard = {
  totalUser: "",
  connectWithExchange: "",
  subcscribeStategies: "",
  disabledAccount: "",
  amountEarned: "",
  amountDue: "",
};
export function Demo1Dashboard() {
  const [dasboardState, setDasboardState] = useState(initDashboard);
  useEffect(() => {
    axios.get("/admin/dashboard").then((res) => {
      // console.log(res);
      if (res) {
        setDasboardState({
          ...dasboardState,
          totalUser: res.data.data.verifiedCount,
          connectWithExchange: res.data.data.exchangeCount,
          subcscribeStategies: res.data.data.strategyCount,
          disabledAccount: res.data.data.disabledCount,
          amountEarned: res.data.data.psEarningTotal,
          amountDue: res.data.data.psEarningDue,
        });
      }
    });
    // eslint-disable-next-line
  }, []);
  // console.log(dasboardState);
  return (
    <>
      <Card className="shadow p-3 mb-5 bg-body rounded">
        <CardBody>
          <div className="row">
            <div className="col-4">
              <Card className="shadow p-3 mb-5 bg-body rounded">
                <CardBody>
                  <div
                    className="row"
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    Total Users
                  </div>
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontWeight: "900",
                      color: "#0073E9",
                    }}
                  >
                    {dasboardState.totalUser.toLocaleString()}
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-4">
              <Card className="shadow p-3 mb-5 bg-body rounded">
                <CardBody>
                  <div
                    className="row"
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    Users with Connected Exchanges
                  </div>
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontWeight: "900",
                      color: "#0073E9",
                    }}
                  >
                    {dasboardState.connectWithExchange.toLocaleString()}
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-4">
              <Card className="shadow p-3 mb-5 bg-body rounded">
                <CardBody>
                  <div
                    className="row"
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    Users with Subscribed Strategies
                  </div>
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontWeight: "900",
                      color: "#0073E9",
                    }}
                  >
                    {dasboardState.subcscribeStategies.toLocaleString()}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <Card
                className="shadow p-3 mb-5 bg-body rounded"
                style={{ height: "100%" }}
              >
                <CardBody style={{ marginTop: "85px" }}>
                  <div
                    className="row"
                    style={{
                      fontSize: "25px",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    Total Disabled Accounts
                  </div>
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      marginTop: "5px",
                      fontSize: "25px",
                      fontWeight: "900",
                      color: "#0073E9",
                    }}
                  >
                    {dasboardState.disabledAccount.toLocaleString()}
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-8">
              <Card
                className="shadow p-3 mb-5 bg-body rounded"
                style={{ height: "100%" }}
              >
                <CardBody>
                  <div
                    className="row"
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      justifyContent: "center",
                    }}
                  >
                    Earnings
                  </div>
                  <div
                    className="row"
                    style={{
                      justifyContent: "center",
                      marginTop: "10px",
                      fontSize: "14px",
                      fontWeight: "900",
                    }}
                  >
                    <div className="col-6">
                      <Card className="shadow p-3 mb-5 bg-body rounded">
                        <CardBody>
                          <div
                            className="row"
                            style={{
                              fontSize: "28px",
                              fontWeight: "bold",
                              justifyContent: "center",
                            }}
                          >
                            Amount Earned
                          </div>
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "5px",
                              fontSize: "25px",
                              fontWeight: "900",
                              color: "#0073E9",
                            }}
                          >
                            {`$ ${dasboardState.amountEarned.toLocaleString()}`}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className="col-6">
                      <Card className="shadow p-3 mb-5 bg-body rounded">
                        <CardBody>
                          <div
                            className="row"
                            style={{
                              fontSize: "28px",
                              fontWeight: "bold",
                              justifyContent: "center",
                            }}
                          >
                            Amount Due
                          </div>
                          <div
                            className="row"
                            style={{
                              justifyContent: "center",
                              marginTop: "5px",
                              fontSize: "25px",
                              fontWeight: "900",
                              color: "#0073E9",
                            }}
                          >
                            {`$ ${dasboardState.amountDue.toLocaleString()}`}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
