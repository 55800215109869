// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import axios from "axios";

// Validation schema
const ResetPasswordSchema = Yup.object().shape({
  DefaultPercentageCommission: Yup.string().matches(
    /[+-]?([0-9]*[.])?[0-9]+$/,
    "Only Numbers",
    ),
  CommisionFrom0to10: Yup.string().matches(
    /[+-]?([0-9]*[.])?[0-9]+$/,
    "Only Numbers",
    ),
  CommisionFrom10to100: Yup.string().matches(
    /[+-]?([0-9]*[.])?[0-9]+$/,
    "Only Numbers",
    ),
  CommisionFrom100to1000: Yup.string().matches(
    /[+-]?([0-9]*[.])?[0-9]+$/,
    "Only Numbers",
    ),
  CommisionFrom1000to10000: Yup.string().matches(
    /[+-]?([0-9]*[.])?[0-9]+$/,
    "Only Numbers",
    ),
  CommisionOver10000: Yup.string().matches(
    /[+-]?([0-9]*[.])?[0-9]+$/,
    "Only Numbers",
    ),
  MinimumPayableAmount: Yup.string().matches(
      /[+-]?([0-9]*[.])?[0-9]+$/,
      "Only Numbers",
    ),
});
const initResetPassword = {
  DefaultPercentageCommission: "",
  CommisionFrom0to10: "",
  CommisionFrom10to100: "",
  CommisionFrom100to1000: "",
  CommisionFrom1000to10000: "",
  CommisionOver10000: "",
  MinimumPayableAmount: "",
};

export function Configurations({
  openConfigurationDialog,
  setOpenComfigurationDialog,
  actionsLoading,
}) {
  const [initState, setInitState] = useState(initResetPassword);
  useEffect(() => {
    axios.get("/public/data").then((res) => {
      // console.log(res.data.data[0], "res");
      setInitState({
        ...initState,
        DefaultPercentageCommission: res?.data?.data[0]?.commissionDefault,
        CommisionFrom0to10: res?.data?.data[0]?.commisionFrom0to10,
        CommisionFrom10to100: res?.data?.data[0]?.commisionFrom10to100,
        CommisionFrom100to1000: res?.data?.data[0]?.commisionFrom100to1000,
        CommisionFrom1000to10000: res?.data?.data[0]?.commisionFrom1000to10000,
        CommisionOver10000: res?.data?.data[0]?.commisionOver10000,
        MinimumPayableAmount: res?.data?.data[0]?.commissionThreshold,
      });
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initState }}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => {
          //   saveUser(values);
          console.log(values);
          if (values) {
            let configuration = {
              commissionDefault: Number(values.DefaultPercentageCommission),
              commisionFrom0to10: Number(values.CommisionFrom0to10),
              commisionFrom10to100: Number(values.CommisionFrom10to100),
              commisionFrom100to1000: Number(values.CommisionFrom100to1000),
              commisionFrom1000to10000: Number(values.CommisionFrom1000to10000),
              commisionOver10000: Number(values.CommisionOver10000),
              commissionThreshold: Number(values.MinimumPayableAmount),
            };

            axios.patch("/public/data", configuration).then((response) => {
              setOpenComfigurationDialog(false);
            });
            console.log(initState);
            console.log(configuration);
          }
          // console.log(configuration);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal
              size="md"
              show={openConfigurationDialog}
              onHide={() => {
                setOpenComfigurationDialog(false);
              }}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title>Configurations</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-10">
                      <Field
                        name="DefaultPercentageCommission"
                        component={Input}
                        placeholder=" % Commission"
                        label="Default Percentage Commission"
                      />
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <span>
                        <i
                          className="fas fa-percent"
                          style={{ marginTop: "36px", color: "#24ACF2" }}
                        ></i>
                      </span>
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-10">
                      <Field
                        name="CommisionFrom0to10"
                        component={Input}
                        placeholder=" % Commission"
                        label="Commision From 0 to 10.000"
                      />
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <span>
                        <i
                          className="fas fa-percent"
                          style={{ marginTop: "36px", color: "#24ACF2" }}
                        ></i>
                      </span>
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-10">
                      <Field
                        name="CommisionFrom10to100"
                        component={Input}
                        placeholder=" % Commission"
                        label="Commision From 10.001 to 100.000"
                      />
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <span>
                        <i
                          className="fas fa-percent"
                          style={{ marginTop: "36px", color: "#24ACF2" }}
                        ></i>
                      </span>
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-10">
                      <Field
                        name="CommisionFrom100to1000"
                        component={Input}
                        placeholder=" % Commission"
                        label="Commision From 100.001 to 1000.000"
                      />
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <span>
                        <i
                          className="fas fa-percent"
                          style={{ marginTop: "36px", color: "#24ACF2" }}
                        ></i>
                      </span>
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-10">
                      <Field
                        name="CommisionFrom1000to10000"
                        component={Input}
                        placeholder=" % Commission"
                        label="Commision From 1000.001 to 10000.000"
                      />
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <span>
                        <i
                          className="fas fa-percent"
                          style={{ marginTop: "36px", color: "#24ACF2" }}
                        ></i>
                      </span>
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-10">
                      <Field
                        name="CommisionOver10000"
                        component={Input}
                        placeholder=" % Commission"
                        label="Commision Over 10000.000"
                      />
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <span>
                        <i
                          className="fas fa-percent"
                          style={{ marginTop: "36px", color: "#24ACF2" }}
                        ></i>
                      </span>
                    </div>
                    {/* Last Name */}
                  </div>
                  <div className="form-group row">
                    {/* First Name */}
                    <div className="col-lg-10">
                      <Field
                        name="MinimumPayableAmount"
                        component={Input}
                        placeholder="USD Amount"
                        label="Minimum Payable Amount"
                      />
                    </div>
                    <div className="col-lg-2">
                      {" "}
                      <span>
                        <i
                          className="fas fa-dollar-sign"
                          style={{ marginTop: "36px", color: "#24ACF2" }}
                        ></i>
                      </span>
                    </div>
                    {/* Last Name */}
                  </div>

                  {/* Email */}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={() => {
                    setOpenComfigurationDialog(false);
                  }}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
