// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  // Select,
  // DatePickerField,
} from "../../../../_metronic/_partials/controls";

// Validation schema
const TransactionEditSchema = Yup.object().shape({
  // name: Yup.string()
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Firstname is required"),
  // varefied: Yup.string()
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Lastname is required"),
  // email: Yup.string()
  //   .email("Invalid email")
  //   .required("Email is required"),
  // transactionName: Yup.string().required("Transactionname is required"),
  // dateOfBbirth: Yup.mixed()
  //   .nullable(false)
  //   .required("Date of Birth is required"),
  // ipAddress: Yup.string().required("IP Address is required"),
});

export function TransactionEditForm({ saveTransaction, transaction, actionsLoading, onHide }) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={transaction}
        validationSchema={TransactionEditSchema}
        onSubmit={(values) => {
          // saveTransaction(values);
          console.log(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Transactions Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-4">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                  {/* Last Name */}
                  <div className="col-lg-4">
                    <Field
                      type="email"
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                    />
                  </div>
                  {/* Login */}
                  <div className="col-lg-4">
                    <Field
                      name="exchangesCount"
                      component={Input}
                      placeholder="Exchanges"
                      label="Exchanges"
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="commissionDue"
                      component={Input}
                      placeholder="Payable"
                      label="Payable"
                    />
                  </div>
                  {/* Date of birth */}
                  <div className="col-lg-4">
                    <Field
                      name="totalProfit"
                      component={Input}
                      placeholder="Profit"
                      label="Profit"
                    />
                  </div>
                  {/* IP Address */}
                  <div className="col-lg-4">
                    <Field
                      name="status"
                      component={Input}
                      placeholder="Status "
                      label="Status"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* Gender */}
                  <div className="col-lg-4">
                    <Field
                      name="strategiesCount"
                      component={Input}
                      placeholder="Strategies "
                      label="Strategies"
                    />
                  </div>
                  {/* Type */}
                  <div className="col-lg-4">
                    <Field
                      name="verified"
                      component={Input}
                      placeholder="Verified "
                      label="Verified"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
