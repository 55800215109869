import { createSlice } from "@reduxjs/toolkit";

const initialStrategiesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  strategyForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const strategiesSlice = createSlice({
  name: "strategies",
  initialState: initialStrategiesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getStrategyById
    strategyFetched: (state, action) => {
      state.actionsLoading = false;
      state.strategyForEdit = action.payload.strategyForEdit;
      state.error = null;
    },
    // findStrategies
    strategiesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createStrategy
    strategyCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateStrategy
    strategyUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteStrategy
    strategyDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // deleteStrategies
    strategiesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    // strategiesUpdateState
    strategiesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
